import * as React from "react"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const index = ({ state, setState }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setState({ ...state, open: false })
  }

  return (
    <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      <Alert onClose={handleClose} severity={state.severity} sx={{ width: "100%" }}>
        { state.severity === 'success' ?
        'Operation succeeded.' : state.message
        }
      </Alert>
    </Snackbar>
  )
}
export default index
