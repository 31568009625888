/* eslint-disable max-len */
import React, { useNavigate, useState, useContext, useRef, useEffect } from 'react'
import axios from '../../lib/axios'
import {
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
  Input,
  Skeleton
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Add, Close, Edit, Done } from '@mui/icons-material'
import { ReactSVG } from 'react-svg'
import './js/SelectedSvg'
import * as Yup from 'yup'
import { handleSvg } from './js/SelectedSvg'
import AdminContext from '../../components/Context/AdminContext'
import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'


const AdminTiles = () => {
  let svgObject = null
  const { admin } = useContext(AdminContext)
  const hiddenFileInput = useRef()
  const [svgFile, setSvgFile]=useState(null)
  // const [svgObject, setSvgObject]=useState({ object_variables: [] })
  const [svgError, setSvgError]=useState(false)
  const EMPTY_TILE = { name: "", color_pattern: 0, tag: "", price: 0, img: "" }
  const [tileInput, setTileInput]=useState(EMPTY_TILE)
  const [tileUpdate, setTileUpdate]=useState({ ...EMPTY_TILE, id: -1 })
  const [tiles, setTiles]=useState([])
  const [selectedTiles, setSelectedTiles] = useState([])
  const [error, setError] = useState(false)
  const [backdrop, setBackdrop]=useState(false)
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: ''
  })
  const [loading, setLoading]=useState(true)

  const validationSchema = Yup.object({
    color_pattern: Yup.number().required('Please Enter a color pattern.'),
    name: Yup.string().required('Please Enter Tile Name'),
    tag: Yup.string().required('Please Select a Tag'),
    image: Yup.object().required('Please select an svg file.'),
    price: Yup.number()
        .min(0, "Price must be more than or equal to 1")
        .max(2000).required('Please Enter a price number.')
  })

  const handleTileUpdate = async () => {
    const selectedTile = tiles.filter((tile) => tileUpdate.id === tile.id)[0]
    if (tileUpdate.name === selectedTile.name &&
      tileUpdate.price === selectedTile.price &&
      tileUpdate.tag === selectedTile.tag &&
      tileUpdate.color_pattern === selectedTile.color_pattern) {
      return setSnackbar({
        open: true,
        message: 'Please change at least one field.',
        severity: 'error'
      })
    }
    try {
      setBackdrop(true)
      await axios.put(`/api/tile/${tileUpdate.id}`, tileUpdate, { headers: { authorization: admin.token } })
      setTiles(tiles.map((tile) => {
        if (tile.id === tileUpdate.id) {
          return tileUpdate
        }
        return tile
      }))
      setSnackbar({
        open: true,
        message: '',
        severity: 'success'
      })
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Oops, something went wrong. Please, try again later.',
        severity: 'error'
      })
    } finally {
      setBackdrop(false)
      setTileUpdate({ ...EMPTY_TILE, id: -1 })
    }
  }

  const handleTileDelete = async (tileId) => {
    try {
      setBackdrop(true)
      await axios.delete(`/api/tile/${tileId}`, { headers: { authorization: admin.token } })
      setTiles(tiles.filter((tile) => {
        return tile.id !== tileId
      }))
      setSnackbar({
        open: true,
        message: '',
        severity: 'success'
      })
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Oops, something went wrong. Please, try again later.',
        severity: 'error'
      })
    } finally {
      setBackdrop(false)
    }
  }

  const handleChange = (event, key, getter, setter) => {
    const updatedObject = { ...getter }
    updatedObject[key] = event.target.value
    setter(updatedObject)
  }

  const resetForm = () => {
    setTileInput(EMPTY_TILE)
    setSvgFile(null)
  }

  const onSubmit = async () => {
    const formInputs = {
      color_pattern: tileInput.color_pattern,
      name: tileInput.name,
      tag: tileInput.tag,
      price: tileInput.price,
      image: svgObject
    }
    try {
      setBackdrop(true)
      const isValid = await validationSchema.isValid(formInputs)
      if (isValid) {
        setError('')
        const response = await axios.post('/api/tile', { ...formInputs, image: JSON.stringify(formInputs.image) }, { headers: { authorization: admin.token } })
        setBackdrop(false)
        setSnackbar({
          open: true,
          message: 'Oops, something went wrong. Please, try again later.',
          severity: 'error'
        })
        resetForm()
      } else {
        setError('The form values are not valid!')
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Oops, something went wrong. Please, try again later.',
        severity: 'error'
      })
    } finally {
      setBackdrop(false)
    }
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const handleSvgChange = (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded.type === 'image/svg+xml') {
      setSvgFile(URL.createObjectURL(fileUploaded))
      setSvgError(false)
    } else {
      setSvgError(true)
    }
  }

  const handleDeleteSelectedTiles = async () => {
    try {
      setBackdrop(true)
      const responses = await Promise.all(selectedTiles.map(async (tileId) => {
        return await axios.delete(`/api/tile/${tileId}`, { headers: { authorization: admin.token } })
      }))
      setTiles(tiles.filter((tile) => !selectedTiles.includes(tile)))
      setSnackbar({
        open: true,
        message: 'Oops, something went wrong. Please, try again later.',
        severity: 'error'
      })
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Oops, something went wrong. Please, try again later.',
        severity: 'error'
      })
    } finally {
      setBackdrop(false)
    }
  }

  const selectTag = (tag) => {
    const copied = { ...tileInput }
    copied["tag"] = tag
    setTileInput(copied)
  }

  const sortedTiles = () => {
    return [...tiles].sort((a, b) => b.id - a.id)
  }

  const fetchTiles = async () => {
    try {
      const response = await axios.get('/api/tile')
      setTiles(response.data.data)
      setLoading(false)
    } catch (error) {
      setError(true)
    }
  }
  useEffect(() => {
    fetchTiles()
  }, [])
  return (
    <Grid container bgcolor='#FAFAFA'>
      <Container
        maxWidth='xl'
        sx={{
          minHeight: '100vh',
          margin: '30px',
          marginTop: '70px',
          padding: '30px' }}
      >
        <Grid
          container
          justifyContent={'center'}
          alignItems='flex-start'
          direction='row'
        >
          <Grid item sm={7} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems='center'
                >
                  <Grid item>
                    <Typography variant='h5' fontWeight={600}>
                      All Tiles
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleDeleteSelectedTiles}
                      className='delete_request_button' variant='contained'>
                    Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mt={3} xs={12}>
                <Grid
                  container
                  bgcolor='white'
                  borderRadius='10px'
                  justifyContent={'center'}
                  direction='column'
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{
                        paddingBottom: '20px',
                        paddingTop: '20px'
                      }}
                    >
                      <Grid item xs={1}>
                        <Checkbox checked={!!selectedTiles.length && selectedTiles.length === tiles.length} onClick={(e) => {
                          if (selectedTiles.length === tiles.length) {
                            setSelectedTiles([])
                          } else {
                            setSelectedTiles(tiles.map((tile) => tile.id))
                          }
                        }} />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant='body2' sx={{ color: '#9CA3AF' }}>
                          Tile Name
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant='body2' sx={{ color: '#9CA3AF' }}>
                          Price(sqm)
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant='body2' sx={{ color: '#9CA3AF' }}>
                          Tag
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant='body2' sx={{ color: '#9CA3AF' }}>
                          Color Pattern
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant='body2' sx={{ color: '#9CA3AF' }}>
                          Action
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {loading ? (
                    <Typography textAlign='center' variant='h4' fontWeight={800}>
                      {('12345678901234'.split('')).map((_, i) => (
                        <Skeleton key={i} variant="rectangular"
                          width={'100%'} height={94} sx={{ margin: '1px 0' }} />
                      )
                      )}
                    </Typography>
                  ) : !tiles.length ? (
                  <Typography textAlign='center' variant='h3' fontWeight={400}>
                    No Tile is Added
                  </Typography>
                  ) : sortedTiles().map((tileObject, tileIndex) => (
                    <Grid key={tileObject.id} item>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          borderTop: "1px solid #ECEDF0",
                          paddingBottom: "20px",
                          paddingTop: "15px"
                        }}
                      >
                        <Grid item xs={1}>
                          <Checkbox checked={!!selectedTiles.includes(tileObject.id)} onClick={(e) => {
                            if (selectedTiles.includes(tileObject.id)) {
                              setSelectedTiles(selectedTiles.filter((tileId) => tileId !== tileObject.id))
                            } else {
                              setSelectedTiles([...selectedTiles, tileObject.id])
                            }
                          }}/>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container alignItems={"center"}>
                            <Grid item ml={1}>
                              {tileUpdate.id !== tileObject.id ? (
                              <Typography variant="body2">
                                {tileObject.name}
                              </Typography>
                              ) : (
                              <Input onChange={(e) => {
                                handleChange(e, 'name', tileUpdate, setTileUpdate)
                              }} value={tileUpdate.name} variant="body2" />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          {tileUpdate.id !== tileObject.id ? (
                              <Typography variant="body2">
                                {tileObject.price}
                              </Typography>
                              ) : (
                              <Input sx={{ maxWidth: '3.4rem' }} onChange={(e) => {
                                handleChange(e, 'price', tileUpdate, setTileUpdate)
                              }} value={tileUpdate.price} variant="body2" />
                              )}
                        </Grid>
                        <Grid item xs={2}>
                          {tileUpdate.id !== tileObject.id ? (
                              <Typography variant="body2">
                                {tileObject.tag}
                              </Typography>
                              ) : (
                              <Input sx={{ maxWidth: '5rem' }} onChange={(e) => {
                                handleChange(e, 'tag', tileUpdate, setTileUpdate)
                              }} value={tileUpdate.tag} variant="body2" />
                              )}
                        </Grid>
                        <Grid item xs={2}>
                          {tileUpdate.id !== tileObject.id ? (
                              <Typography variant="body2">
                                {tileObject.color_pattern}
                              </Typography>
                              ) : (
                              <Input sx={{ maxWidth: '3rem' }} onChange={(e) => {
                                handleChange(e, 'color_pattern', tileUpdate, setTileUpdate)
                              }} value={tileUpdate.color_pattern} variant="body5" />
                              )}
                        </Grid>
                        <Grid item xs={2}>
                          <Grid container>
                            <Grid>
                              <Typography variant="body2">
                                {tileObject.id !== tileUpdate.id ? (
                                <Edit
                                  sx={{ marginLeft: "10px", cursor: "pointer" }}
                                  onClick={(e) => {
                                    setTileUpdate({ ...tileObject })
                                  }}
                                />
                                  ) : (
                                  <span>
                                    <Done
                                      sx={{ color: 'green', marginLeft: "10px", cursor: "pointer" }}
                                      onClick={handleTileUpdate}
                                    />
                                    <Close
                                      sx={{ color: 'red', marginLeft: "3px", cursor: "pointer" }}
                                      onClick={(e) => {
                                        setTileUpdate({ EMPTY_TILE, id: -1 })
                                      }}
                                    />
                                  </span>
                              )}
                              </Typography>
                            </Grid>
                            <Grid onClick={(e) => handleTileDelete(tileObject.id)}>
                              <Typography variant="body2">
                                <DeleteIcon
                                  sx={{ marginLeft: "10px", cursor: "pointer" }}
                                />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item sm={5} xs={12}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems='center'
                >
                  <Grid item
                    sx={{ marginLeft: '20px' }}
                  >
                    <Typography variant='h5' fontWeight={600}>
                      Add New Tile
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                ml='1'
                item mt={3} xs={12}>
                <Grid
                  container
                  bgcolor='white'
                  borderRadius='10px'
                  justifyContent={'center'}
                  direction='column'
                  sx={{ marginLeft: '20px', padding: '20px' }}
                >
                  <Grid item xs={12}>
                    <Grid container direction='column'>
                      <Grid item xs={12}>
                        <Grid container sx={{ minHeight: '500px' }} justifyContent={'flex-start'} alignItems='center' direction='column'>
                          <Grid item xs={8}>
                            {svgFile ? (
                              <Grid container position='relative' justifyContent={'center'} direction='column' alignItems='center' className='downloadContainer'>
                                <IconButton sx={{ position: 'absolute', right: 3, top: 3 }} onClick={(e) => {
                                  svgObject = null
                                  setSvgFile(null)
                                }}>
                                  <Close />
                                </IconButton>
                                <ReactSVG
                                  afterInjection={(error, svg) => {
                                    if (error) {
                                      return
                                    }
                                    svgObject = { ...handleSvg(svg), bg_color: '#ffffff', object_used_colors: [] }
                                  }}
                                  beforeInjection={(svg) => {
                                    svg.classList.add("svg-class-name")
                                    svg.setAttribute("id", "current_selected_svg")
                                    svg.setAttribute("style", "width: 100%")
                                  }}
                                  className="wrapper-class-name"
                                  evalScripts="never"
                                  fallback={() => <span>We got some error please try again!</span>}
                                  httpRequestWithCredentials={false}
                                  loading={() => <span>Loading...</span>}
                                  onClick={() => {
                                  }}
                                  renumerateIRIElements={false}
                                  src={svgFile}
                                  useRequestCache={true}
                                  wrapper="span"
                                />
                              </Grid>
                            ):(
                              <Grid container justifyContent={'center'} direction='column' alignItems='center' className='downloadContainer'>
                                <Grid item>
                                  <img src='/Vector.png' alt='download'/>
                                </Grid>
                                <Grid item sx={{ marginTop: '20px' }}>
                                  <Typography variant='body1' fontWeight={700}>
                                  Drag and drop files or <a onClick={handleClick} style={{ color: '#1573FF', cursor: 'pointer' }}>Browse</a>
                                  </Typography>
                                  <input
                                    type='file'
                                    id='Svg_Input'
                                    ref={hiddenFileInput}
                                    onChange={handleSvgChange}
                                    style={{ display: 'none' }} />
                                  <Typography align='center' sx={{ marginTop: '15px' }} variant='body1' color='#B8B8B8'>
                                  Support SVG file
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {svgError ? (
                              <Typography sx={{ marginTop: '15px', color: 'red' }} variant='body1'>
                              Please Select svg file !
                              </Typography>
                            ):(
                              <Typography sx={{ marginTop: '15px', color: '#999999' }} variant='body1'>
                              Select pattern color areas
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container gap={1} justifyContent={'center'} alignItems='center' sx={{ marginTop: '-50px' }}>
                          <Grid item md={5} xs={12}>
                            <Grid container gap={2}>
                              <Grid item xs={12}>
                                <InputLabel>
                                  <Typography variant='h6' fontWeight={700} color='black'>
                                    Tile Name
                                  </Typography>
                                </InputLabel>
                                <OutlinedInput
                                  className='admin_tiles'
                                  fullWidth
                                  value={tileInput.name}
                                  onChange={(e) => handleChange(e, 'name', tileInput, setTileInput)}
                                  sx={{ marginTop: '5px' }}
                                  id="outlined-adornment-weight"
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight'
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>
                                  <Typography variant='h6' fontWeight={700} color='black'>
                                    Sqm Price
                                  </Typography>
                                </InputLabel>
                                <OutlinedInput
                                  className='admin_tiles'
                                  fullWidth
                                  value={tileInput.price}
                                  onChange={(e) => handleChange(e, "price", tileInput, setTileInput)}
                                  sx={{ marginTop: '5px' }}
                                  id="outlined-adornment-weight"
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight'
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={5} sx={{ marginLeft: '30px' }} xs={12}>
                            <Grid container gap={6}>
                              <Grid item xs={12}>
                                <Grid container gap={1}>
                                  <Grid item xs={12}>
                                    <Typography variant='h6' fontWeight={700}>Tile Tag</Typography>
                                  </Grid>
                                  <Grid item sx={{ marginTop: '5px' }} xs={5}>
                                    <Button
                                      sx={{
                                        "borderColor": 'black',
                                        "zIndex": 2,
                                        "marginTop": "-10px",
                                        "height": "55px",
                                        "borderRadius": 100,
                                        "color": tileInput.tag === 'Tile' ? 'white' : 'black',
                                        "padding": 1,
                                        '&:focus': {
                                          backgroundColor: '#262626',
                                          color: 'white'
                                        }
                                      }}
                                      onClick={(e) => selectTag('Tile')}
                                      variant={tileInput.tag === 'Tile' ? 'contained' : 'outlined'}
                                      fullWidth
                                    >
                                      Tile
                                    </Button>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Button
                                      sx={{
                                        "borderColor": 'black',
                                        "zIndex": 2,
                                        "borderRadius": 100,
                                        "marginTop": "-5px",
                                        "height": "55px",
                                        "color": tileInput.tag === 'Hexagon' ? 'white' : 'black',
                                        "padding": 1,
                                        '&:focus': {
                                          backgroundColor: '#262626',
                                          color: 'white'
                                        }
                                      }}
                                      onClick={(e) => selectTag('Hexagon')}
                                      variant={tileInput.tag === 'Hexagon' ? 'contained' : 'outlined'}
                                      fullWidth
                                    >
                                      Hexagon
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container gap={1}>
                                  <Grid item xs={5}>
                                    <Button
                                      sx={{
                                        "backgroundColor": '#EFEFEF',
                                        "borderColor": '#EFEFEF',
                                        "padding": 1,
                                        "zIndex": 2,
                                        "height": "55px",
                                        "borderRadius": 100,
                                        "color": 'black'
                                      }}
                                      fullWidth
                                    >
                                      Cancel
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button
                                      sx={{
                                        "backgroundColor": '#E7C8CD',
                                        "borderColor": '#E7C8CD',
                                        "padding": 1,
                                        "zIndex": 2,
                                        "borderRadius": 100,
                                        "height": "55px",
                                        "color": 'black',
                                        '&:focus': {
                                          backgroundColor: '#E7C8CD',
                                          color: 'black',
                                          borderColor: '#E7C8CD'
                                        },
                                        '&:hover': {
                                          backgroundColor: '#E7C8CD',
                                          color: 'black',
                                          borderColor: '#E7C8CD'
                                        }
                                      }}
                                      onClick={onSubmit}
                                      fullWidth
                                      variant='outlined'
                                    >
                                      <Add></Add>
                                      Add Tile
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {error ? (
                            <Typography variant='body' color='red'>{error}</Typography>
                          ) : ('')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop open={backdrop} setOpen={setBackdrop}/>
        <Snackbar
          state={snackbar}
          setState={setSnackbar} />
      </Container>
    </Grid>
  )
}

export default AdminTiles
