import * as React from "react"
import { Backdrop, CircularProgress } from "@mui/material"

const index = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
export default index
