import Router from './routes/router'
import './App.css'
import AdminContext from './components/Context/AdminContext'
import { useState } from 'react'
import Cookies from 'universal-cookie'

const App = () => {
  const cookies = new Cookies()
  const [admin, setAdmin] = useState({
    isAuthenticated: false,
    token: cookies.get('authorization'),
    username: '',
    loading: true })
  return (
    <AdminContext.Provider value={{ admin, setAdmin }}>
      <Router />
    </AdminContext.Provider>
  )
}

export default App
