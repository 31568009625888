import React, { useEffect, useRef, useState,
  lazy, Suspense, useContext } from 'react'
import { useRoutes, Navigate } from 'react-router-dom'
import axios from '../lib/axios'
import Cookies from 'universal-cookie'
import Tiles from '../data/Tiles.json'
/* ---Components--- */
import Layout from '../components/Layout'
import AdminLayout from '../components/AdminLayout'
import AdminTiles from '../pages/Admin/AdminTiles'
import AdminContext from '../components/Context/AdminContext'

/* ---Routes--- */
const Editor = lazy(() => import('../pages/Editor'))
const Order = lazy(() => import('../pages/Order'))
const Ordered = lazy(() => import('../pages/Ordered'))
const AdminLogin = lazy(() => import('../pages/Admin/AdminLogin'))
const AdminOrders = lazy(() => import('../pages/Admin/AdminOrders'))
const AdminColors = lazy(() => import('../pages/Admin/AdminColors'))
const AdminForgotPassword = lazy(() => import('../pages/Admin/ForgotPassword'))
const ProtectedRoute = lazy(() => import('../components/Routes/ProtectedRoute'))
const GuestRoute = lazy(() => import('../components/Routes/GuestRoute'))
const NotFound = lazy(() => import('../pages/NotFound'))

const Router = () => {
  const [scale, setScale] = useState(0)
  const [mode, setMode] = useState('single')
  const [modal, setModal] = useState(false)
  const [preview, setPreview] = useState('null')
  const [currentColor, setCurrentColor] = useState('#FFFFFF')
  const [currentCategory, setCurrentCategory] = useState('Tile 20x20')
  const [currentPattern, setCurrentPattern] = useState(Tiles['Tile'])
  const [layingTypes, setLayingTypes] = useState([])
  const [colors, setColors] = useState([])
  const { admin, setAdmin } = useContext(AdminContext)
  const svgRef = useRef()
  useEffect(() => {
    const getColors = async () => {
      const { data: colors } = await axios.get('/api/colors')
      setColors(colors)
    }
    const getAdmin = async () => {
      try {
        const cookies = new Cookies()
        const token = cookies.get('authorization')
        if (!token) throw Object({ message: 'No token' })
        const adminResponse = await axios.get('/api/info',
            { headers: { authorization: token } })
        const { data } = adminResponse.data
        setAdmin({
          ...admin,
          isAuthenticated: true,
          username: data.name,
          token,
          loading: false
        })
      } catch (error) {
        setAdmin({
          isAuthenticated: false,
          loading: false
        })
      }
    }
    if (!colors.length) {
      getColors()
    }
    if (!admin.isAuthenticated) {
      getAdmin()
    }
  }, [])
  const element = useRoutes([
    {
      element: (
        <Suspense fallback={<div />}>
          <Layout>
            <Editor
              colors={colors}
              scale={scale}
              setScale={setScale}
              mode={mode}
              setMode={setMode}
              setModal={setModal}
              modal={modal}
              preview={preview}
              setPreview={setPreview}
              currentColor={currentColor}
              setCurrentColor={setCurrentColor}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
              currentPattern={currentPattern}
              setCurrentPattern={setCurrentPattern}
              layingTypes={layingTypes}
              setLayingTypes={setLayingTypes}
              ref={svgRef}
            />
          </Layout>
        </Suspense>
      ),
      path: '/'
    },
    {
      element: (
        <Suspense fallback={<div />}>
          {currentPattern.id !== 0 ? (
          <Layout>
            <Order currentPattern={currentPattern} />
          </Layout>
          ) : (
          <Navigate to="/" replace />
          )}
        </Suspense>
      ),
      path: '/order'
    },
    {
      element: (
        <Suspense fallback={<div />}>
          {currentPattern.id !== 0 ? (
          <Layout>
            <Ordered setLayingTypes={setLayingTypes} />
          </Layout>
          ) : (
          <Navigate to="/" replace />
          )}
        </Suspense>
      ),
      path: '/ordered'
    },
    {
      element: (
        <GuestRoute>
          <Suspense fallback={<div />}>
            <AdminLogin />
          </Suspense>
        </GuestRoute>
      ),
      path: '/admin/login'
    },
    {
      element: (
        <ProtectedRoute>
          <Suspense fallback={<div />}>
            <AdminLayout>
              <AdminOrders colors={colors} />
            </AdminLayout>
          </Suspense>
        </ProtectedRoute>
      ),
      path: '/admin/orders'
    },
    {
      element: (
        <ProtectedRoute>
          <Suspense fallback={<div />}>
            <AdminLayout>
              <AdminColors />
            </AdminLayout>
          </Suspense>
        </ProtectedRoute>
      ),
      path: '/admin/colors'
    },
    {
      element: (
        <GuestRoute>
          <Suspense fallback={<div />}>
            <AdminForgotPassword />
          </Suspense>
        </GuestRoute>
      ),
      path: '/admin/forgot-password'
    },
    {
      element: (
        <ProtectedRoute>
          <Suspense fallback={<div />}>
            <AdminLayout>
              <AdminTiles />
            </AdminLayout>
          </Suspense>
        </ProtectedRoute>
      ),
      path: '/admin/tiles'
    },
    {
      element: (
        <ProtectedRoute>
          <Navigate to="/admin/tiles" replace />
        </ProtectedRoute>
      ),
      path: '/admin'
    },
    {
      element: (
        <NotFound />
      ),
      path: '*'
    }
  ])
  return element
}

export default Router
